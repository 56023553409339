@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    font-size: 75%; /* = 12px */
  }
  @screen sm {
    :root {
      font-size: 87.5%; /* = 14px */
    }
  }
  @screen md {
    :root {
      font-size: 100%; /* = 16px */
    }
  }
}
